<template>
    <div class="wrapper special-detail flex flex-column">
        <a-affix :offset-top="0">
            <div style="background: #eff4ff;">
                <Header :navList="navList" :typeList="[]" @search="handleSearchEvent" :navCurrentId="navCurrentId" :logo="logoUrl"></Header>
            </div>
        </a-affix>
        <div class="flex-item">
            <!--    内容部分     -->
            <div class="container content flex x-left">
                <div class="left-box">
                    <a-spin :spinning="loading" tip="Loading...">
                        <div v-if="specialInfo" class="mb30">
                            <template v-if="type == '2'">
                                <img style="width:100%;" :src="specialInfo.default_pic_url | urlFilter(750)" alt="">
                                <div class="t-l mt20 fs16 color333" v-if="specialInfo.title">{{ specialInfo.title }}
                                </div>
                                <div class="flex x-between mt15 color999 fs14">
                                    <span v-if="specialInfo.video_number">视频数量：{{ specialInfo.video_number }}</span>
                                    <span v-if="specialInfo.video_hits">播放量：{{ specialInfo.video_hits }}</span>
                                </div>
                            </template>
                            <template v-else>
                                <img style="width:100%;" :src="specialInfo.default_pic_url | urlFilter(750)" alt="">
                                <div v-if="specialInfo.content" class="mt20 content-text" :class="{ 'row-3': is_more }">
                                    <div ref="contentRef" class="content-p" v-html="specialInfo.content"></div>
                                    <span v-if="is_more" @click="is_more = false;" class="more-btn">
                                        更多
                                        <a-icon class="more-btn-icon" type="double-right" />
                                    </span>
                                </div>
                            </template>
                        </div>
                        <template v-if="type == 2">
                            <a-tabs class="info-tab-pane" v-if="specialInfo && specialInfo.menu_list.length" v-model:activeKey="activeKey">
                                <a-tab-pane :key="index" :tab="item.title" v-for="(item, index) in specialInfo.menu_list">
                                    <!-- info简介 content内容 catalogue目录 relevant相关视频 author专家介绍 -->
                                    <!-- 简介 -->
                                    <template v-if="item.module_type == 'info'">
                                        <div class="t-l color333 mt10" v-if="item.content" v-html="item.content"></div>
                                        <a-empty class="mt30" v-if="!item.content && !loading" />
                                    </template>
                                    <!-- 目录 -->
                                    <template v-if="item.module_type == 'catalogue'">
                                        <div v-if="item.content.length">
                                            <div class=" user-box" v-for="(item_v, index_v) in item.content" :key="index_v">
                                                <div class="flex x-left flex-wrap mt20" @click="videoJumpDetail(item_v.video_id)">
                                                    <across-media-item :item="item_v" style="width:100%;">
                                                        <template v-slot:bottom>
                                                            <div>
                                                                <div class="y-center color999 fs14 flex x-between">
                                                                    <span class="row-1" style="width:calc(100% - 100px)">
                                                                        <span v-if="item_v.real_name">讲者：{{
                                                                            item_v.real_name
                                                                        }}</span>
                                                                    </span>
                                                                    <span>
                                                                        <i class="med med-yanjing"></i>
                                                                        {{ item_v.video_hits || 0 }}
                                                                    </span>
                                                                </div>
                                                                <div class="flex across-bottom y-center x-between color999 fs14 mt10">
                                                                    <span class="row-1" style="width:calc(100% - 90px)">
                                                                        <span v-if="item_v.org_cnname">单位：{{
                                                                            item_v.org_cnname
                                                                        }}</span></span>
                                                                    <span class="watch-playback">观看视频</span>
                                                                </div>
                                                            </div>
                                                        </template>
                                                    </across-media-item>
                                                </div>
                                            </div>
                                        </div>
                                        <a-empty class="mt30" v-if="!item.content.length && !loading" />
                                    </template>
                                    <!-- 专家介绍 -->
                                    <template v-if="item.module_type == 'author'">
                                        <div v-if="item.content.length">
                                            <div class="flex x-left mb20 pb20" style="border-bottom:1px solid #eee;cursor:pointer;" v-for="(item_p, index_p) in item.content" :key="index_p" @click="expertJumpDetail(item_p.id)">
                                                <!-- handlePersonDetailEvent(index, index_p) -->
                                                <img class="person-item" :src="item_p.avatar | urlFilter(250, 'avatar') || require('@/assets/images/person-default.png')" alt="">
                                                <div class="flex-column x-left t-l" style="width:calc(100% - 90px);">
                                                    <div class="flex x-between y-center mb5">
                                                        <span class="fs16 color333 strong">{{ item_p.real_name }}</span>
                                                        <span v-if="item_p.fans_status == 0" class="watch-playback" style="padding:2px 15px;" @click.stop="getFollowExperts(item_p.id,index,index_p)">关注</span>
                                                        <span v-if="item_p.fans_status == 1" style="padding:2px 15px;border:1px solid #ccc;color:#ccc;border-radius:20px;" @click.stop="getFollowExperts(item_p.id,index,index_p)">取消关注</span>
                                                    </div>
                                                    <span class="flex x-left color999 mb5" style="font-size:15px;">单位：<span class="row-1 color333" style="width:calc(100% - 50px)">{{
                                                                item_p.org_cnname
                                                            }}</span></span>
                                                    <div class="flex x-left color999" style="font-size:15px;">简历：<div class="row-2 color333 t-l" style="width:calc(100% - 50px);" v-html="getSimpleText(item_p.resume || '')">
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <a-empty class="mt30" v-if="!item.content.length && !loading" />
                                    </template>
                                    <!-- 相关 -->
                                    <template v-if="item.module_type == 'relevant'">
                                        <div class="flex list-box x-left flex-wrap" v-if="item.content.length">
                                            <router-link  class="list-box-item" :to="{ path: '/special-detail',query:{type_id:it.id}}" :class="[, { 'ml20': idx % 3 != 0 }, 'mt20']" v-for="(it, idx) in item.content" :key="idx" @click.native="handleRefreshEvent">
                                                <vertical-media-item type="2" gap="0" width="230px" height="130px" :item="{ id: it.id, img: it.default_pic_url, title: it.title }">
                                                    <div slot="vertical-bottom" class="flex x-between y-center pl10 pr10 pb10 color999">
                                                        <span class="fs16 strong" style="color:#f37241;">{{ !it.price ||!parseFloat(it.price) ? '免费' : '￥'+it.price }}</span>
                                                        <span>视频数量{{ it.video_number }}</span>
                                                    </div>
                                                </vertical-media-item>
                                            </router-link>
                                        </div>
                                        <a-empty class="mt30" v-if="!item.content.length && !loading" />
                                    </template>
                                </a-tab-pane>
                            </a-tabs>
                        </template>
                        <template v-else>
                            <a-tabs class="info-tab-pane" v-model="activeKey">
                                <a-tab-pane v-for="(item,index) in tabArr" :tab="item.title" :key="index">
                                    <template v-if="item.id == 0 && specialInfo">
                                        <div ref="contentRef" class="content-p" v-html="specialInfo.content"></div>
                                    </template>
                                    <template v-if="item.id == 1">
                                        <div class="flex x-between y-center search-box" v-if="type == 1">
                                            <a-select
                                                v-model="date_id"
                                                placeholder="请选择日期"
                                                style="width: 33%;"
                                                allowClear
                                                @change="getList">
                                                <a-select-option v-for="(item, index) in filterSearchParams.progranm_date" :key="index" :value="item.id">
                                                    {{ item.meeting_date }}
                                                </a-select-option>
                                            </a-select>
                                            <a-select
                                                v-model="hall_id"
                                                placeholder="请选择会场"
                                                style="width: 33%;"
                                                allowClear
                                                @change="getList">
                                                <a-select-option v-for="(item, index) in filterSearchParams.progranm_hall" :key="index" :value="item.id">
                                                    {{ item.meeting_hall }}
                                                </a-select-option>
                                            </a-select>
                                            <a-input placeholder="请输入关键字" style="width: 33%" @change="getList" v-model="keyword" />
                                        </div>
                                        <div class="list-box mt20">
                                            <div class="list-item flex x-left y-center" v-for="(item,index) in videoList" :key="index" @click="videoJumpDetail(item.id)">
                                                <across-media-item :item="item" style="width:100%;">
                                                    <div slot="desc" class="mt10 color999 fs14 desc">
                                                        讲者 ： {{ item.author }} <span class="ml20">{{ item.author_org }}</span>
                                                    </div>
                                                    <div slot="bottom" class="video-btn">
                                                        观看回放
                                                    </div>
                                                </across-media-item>
                                            </div>
                                        </div>
                                        <a-empty class="mt30" v-if="!videoList.length && !loading" />
                                        <div class="t-c mt30" v-if="dataCount > 0">
                                            <a-pagination :total="dataCount" v-model:current="params.page" :page-size="params.pageSize" show-quick-jumper @change="handleVideoPageChangeEvent" :hideOnSinglePage="true" />
                                        </div>
                                    </template>
                                </a-tab-pane>
                            </a-tabs>
                        </template>
                    </a-spin>
                </div>
                <div class="right-box ml35 flex-item">
                    <layout-right :config="layoutRightConfig"></layout-right>
                </div>
            </div>
        </div>
        <Footer></Footer>
        <a-modal title="专家简介" class="person-detail" centered :visible="visible" @cancel="handleCancelEvent" :footer="null">
            <div class="flex x-left mb20 pb20">
                <img class="mr10" style="width:80px;height:80px;object-fit: cover;object-position: top;border-radius: 50%;" :src="detailItem.avatar | urlFilter(250, 'avatar') || require('@/assets/images/person-default.png')" alt="">
                <div class="flex-column x-left t-l" style="width:calc(100% - 90px);">
                    <div class="flex x-between y-center mb5">
                        <span class="fs16 color333 strong">{{ detailItem.real_name }}</span>
                    </div>
                    <span class="flex x-left color999 mb5" style="font-size:15px;">单位：<span class="color333" style="width:calc(100% - 50px)">{{
                                detailItem.org_cnname
                            }}</span></span>
                    <span class="flex x-left color999" style="font-size:15px;">简历：<span class="color333 t-l" style="width:calc(100% - 50px);" v-html="getSimpleText(detailItem.resume || '')"></span>
                    </span>
                </div>
            </div>
        </a-modal>
    </div>
</template>
<script>
import Header from "@/components/layout/layout-header";
import layoutRight from '@/components/layout/layout-right';
import Footer from "@/components/layout/layout-footer";
import verticalMediaItem from '@/components/vertical-media-item.vue';
import acrossMediaItem from '@/components/across-media-item';
import { videoLogo, mediaNavList } from "@/config/const";
import { jsonFormat } from '@/utils/jsonFormat';
import { dateFormat, UTCToLocalTimeString } from '@/utils/dateFormat.js';
import { committee_id } from '@/config';
import { videoJumpDetail, expertJumpDetail, liveJumpDetail } from '@/utils/jumpPageMethods'
export default {
    name: "SpecialDetail",
    components: {
        Header,
        layoutRight,
        Footer,
        verticalMediaItem,
        acrossMediaItem,
    },
    data() {
        return {
            videoJumpDetail,
            expertJumpDetail,
            liveJumpDetail,
            navList: mediaNavList,
            logoUrl: videoLogo,
            navCurrentId: 3,
            type: this.$route.query.type || 2,
            // 专题视频列表
            dataList: [],
            videoPlaybackList: {}, //会议回放列表
            activeKey: 1,
            // 分页
            params: {
                page: 1,
                pageSize: 10,
            },
            videoParams: {
                page: 1,
                pageSize: 10,
                count: 0
            },
            // 条数
            dataCount: 0,
            is_more: false,
            specialInfo: null,
            layoutRightConfig: {
                news: {
                    show: true, // 是否显示
                    methods: 'post',
                    title: '新闻资讯', // 标题
                    data: {
                        page: 1,
                        limit: 5,
                        type: 2,
                    },   // 参数条件
                },
                meeting: {
                    show: true, // 是否显示
                    title: '推荐会议', // 标题
                    methods: 'get',
                    data: {
                        year: new Date().getFullYear(),
                        month: new Date().getMonth() + 1,
                        page: 1,
                        pageSize: 3,
                        is_now_year_meeting: 1,
                    },   // 参数条件
                },
                // 直播
                live: {
                    show: true, // 是否显示
                    methods: 'get',
                    title: '推荐直播', // 标题
                    data: {
                        page: 1,
                        limit: 3
                    },   // 参数条件
                },
                // 指南
                guideDownload: {
                    show: true, // 是否显示
                    title: '临床指南下载', // 标题
                    methods: 'post',
                    data: {
                        limit: 5,
                        operate_id: "",
                        page: 1,
                    },   // 参数条件
                },
            },
            loading: true,
            visible: false,
            detailItem: {},
            filterSearchParams : {
                progranm_date : [],
                progranm_hall : []
            },// 筛选项
            videoList : [],
            hall_id : undefined,
            date_id : undefined,
            keyword : '',
            tabArr : [
                {
                    title : '简介',
                    id : 0
                },
                {
                    title : '目录',
                    id : 1
                }
            ]
        };
    },
    created() {
        let { type, type_id } = this.$route.query;
        this.__type = type || 2;
        this.__type_id = type_id;
        this.pageInit();
        if (type != 2) {
            this.getList()
        }
    },
    methods: {
        // 关闭专家简介弹窗
        handleCancelEvent() {
            this.visible = false
        },
        // 富文本转纯文本
        getSimpleText(html) {
            let re1 = new RegExp("<.+?>", "g");
            let msg = html.replace(re1, '');
            return msg;
        },
        // 专家简介
        handlePersonDetailEvent(index, index_p) {
            this.visible = true
            this.detailItem = this.specialInfo.menu_list[index].content[index_p]
        },
        //关注
        getFollowExperts(userid, index, index_p) {
            this.request.post('FollowExperts', { experts_uid: userid }).then(res => {
                if (res.data.code == 200) {
                    this.specialInfo.menu_list[index].content[index_p].fans_status = this.specialInfo.menu_list[index].content[index_p].fans_status == 0 ? 1 : 0
                }
            })
        },
        // 刷新
        handleRefreshEvent() {
            this.$router.go(0)
        },
        // 搜索
        handleSearchEvent(e) {
            this.__keyword = e;
            this.params.page = 1;
            this.getList()
        },
        // 会议回放分页
        handleVideoPageChangeEvent(page) {
            let { pageSize } = this.params;
            this.getList();
        },
        getList(type, type_id) {
            this.loading = true;
            if (this.__type == 2) {
                this.loading = false
            } else {
                this.getMeetingVideoList();
            }
        },
        getMeetingVideoList(){
            let data = {
                meeting_id: this.__type_id,
                page: this.params.page,
                pageSize: this.params.pageSize,
            };
            this.keyword && (data['keyword'] = this.keyword);// 关键字搜索
            this.hall_id && (data['hall_id'] = this.hall_id);// 地址Id
            this.date_id && (data['date_id'] = this.date_id);// 日期Id
            this.request.get('getMeetingVideoList', data ).then(res => {
                if (res.code == 200) {
                    let obj = {
                        img : 'default_pic_url',
                        title : 'title',
                    }

                    this.videoList = jsonFormat(res.data.list,obj);
                    this.dataCount = parseInt(res.data.count);
                    this.loading = false;
                }
            })
        },
        pageInit(type, type_id) {
            this.loading = true;
            let requestArr = []
            if (this.type == 2) {
                requestArr = [
                    this.request.get('GetSpecialInfoNew', { type_id: this.__type_id, }),
                ]
            } else {
                requestArr = [
                    this.request.get('GetSpecialInfo', { type: this.__type, type_id: this.__type_id, }),
                    this.request.get('getMeetingVideoFilter',{ meeting_id: this.__type_id}),
                ]
            }
            Promise.all(requestArr).then(res => {
                if (this.__type != 2) {
                    res[0].data.default_pic_url = res[0].data.card_img
                    this.specialInfo = res[0].data;

                    this.filterSearchParams = res[1].data;
                } else {
                    let specialInfo = res[0].data
                    specialInfo.menu_list.forEach(item => {
                        if (item.module_type == 'catalogue') {
                            // this.videoParams.count = item.content.length
                            let obj = {
                                img: 'default_pic_url',
                                title: 'video_title'
                            }
                            item.content = jsonFormat(item.content, obj)
                        }
                    })
                    this.specialInfo = specialInfo
                }
                this.loading = false
                this.$nextTick(() => {
                    if (this.$refs.contentRef) {
                        const initHeight = 63;
                        let { clientHeight } = this.$refs.contentRef;
                        if (clientHeight > initHeight) {
                            this.is_more = true;
                        }
                    }
                })
            })

        },
    },
};
</script>

<style scoped lang="less">
.wrapper {
    min-height: 100vh;
    background: url("~@/assets/images/live-nav-top-bg.png") repeat-x;
    background-size: 1920 auto;
}

.content {
    background: #ffffff;
    border-radius: 10px;
    padding: 20px;
    margin: 15px auto 0;

    .left-box {
        width: 730px;
        min-height: 500px;

        .content-text {
            position: relative;

            &.row-3 {
                height: 63px;

                .content-p {
                    position: absolute !important;
                }
            }

            .content-p {
                position: static;
                left: 0;
                top: 0;
                right: 0;
                font-size: 14px !important;
                line-height: 1.5 !important;
                text-align: left;

                /deep/ * {
                    // font-size: 14px !important;
                    // line-height: 1.5 !important;
                    max-width: 100%;
                }
            }

            .more-btn {
                position: absolute;
                right: 0;
                bottom: 0;
                width: 100%;
                font-size: 14px !important;
                line-height: 1.5 !important;
                padding-right: 10px;
                text-align: center;
                color: @theme;
                cursor: pointer;
                padding-top: 25px;
                background-image: linear-gradient(
                    to top,
                    rgba(255, 255, 255, 1) 0%,
                    rgba(255, 255, 255, 1) 50%,
                    rgba(255, 255, 255, 0) 100%
                );

                i {
                    transform: rotateZ(90deg);
                }
            }
        }

        .user-box {
            border-bottom: 1px solid #f1f1f1;
        }

        .watch-playback {
            color: #f3a641;
            border: 1px solid #f3a641;
            padding: 2px 10px;
            border-radius: 20px;

            &:hover {
                background: #f3a641;
                color: #ffffff;
            }
        }

        .new-playback {
            color: #1e51c9;
            border: 1px solid #1e51c9;
            padding: 2px 10px;
            border-radius: 20px;

            &:hover {
                background: #1e51c9;
                color: #ffffff;
            }
        }

        .info-tab-pane {
            /deep/.ant-tabs-nav {
                float: left;
            }

            /deep/.ant-tabs-tab {
                font-size: 20px;

                &:hover {
                    color: #1e51c9;
                }
            }

            /deep/.ant-tabs-tab-active {
                color: #1e51c9;
            }

            /deep/.ant-tabs-ink-bar {
                background: #1e51c9;
            }

            .person-item {
                width: 80px;
                height: 80px;
                object-fit: cover;
                object-position: top;
                border-radius: 50%;
                margin-right: 10px;
            }

            .follow-btn {
                border-radius: 20px;
                border: 1px solid #1e51c9;
                color: #1e51c9;
                // background: linear-gradient(to right, #5F96E6, #1E51C9);
            }
        }

        .user-avatar {
            width: 60px;
            height: 60px;
            object-fit: cover;
            object-position: top;
            border-radius: 50%;
            margin-right: 10px;
        }

        .user-name {
            font-size: 16px;
            color: #000;
        }

        .user-org {
            font-size: 14px;
            color: #999;
        }

        .user-enter-btn {
            color: @theme;
            border: 1px solid @theme;
            padding: 8px 10px;
            height: 30px;
            line-height: 1;
            display: block;
            font-size: 14px;
            border-radius: 30px;
        }
    }

    .right-box {
        .top-list {
            .ranking-item {
                color: @title;
                margin-bottom: 15px;
                font-weight: bold;
                cursor: pointer;

                img {
                    width: 15px;
                }

                &:nth-child(n + 4) {
                    color: @desc;
                    font-weight: 400;
                }
            }
        }
    }
}

.person-detail {
    /deep/.ant-modal {
        width: 700px !important;
    }

    /deep/.ant-modal-content {
        width: 700px;
    }

    /deep/.ant-modal-body {
        max-height: 400px;
        overflow: auto;
    }
}

.video-btn{
    border: solid 1px #f37241;
    border-radius: 13px;
    color: #f37241;
    font-size: 12px;
    outline: none;
    background: transparent;
    padding: 2px 10px;
    cursor: pointer;
    transition: all 0.3s linear;
    width: max-content;
    margin-left: auto;
    margin-right: 2px;
}

@media screen and (max-width: 768px) {
    /deep/.unlogin-header,/deep/.login-header{
        right: 30px!important;
    }
    .special-detail {
        .content {
            margin: 0;
            border-radius: 0;
            padding: 10px;
            display: block;
        }
        .left-box,
        .right-box {
            width: 100%;
        }
        .right-box {
            margin-left:0;
            padding-top:20px;
        }
        /deep/.ant-tabs-bar{
            margin:0;
        }
        /deep/.ant-tabs-tab {
            font-size: 16px!important;
            margin: 0;
            &.ant-tabs-tab-active {
                font-weight: bold;
            }
        }
        /deep/.ant-tabs-nav {
            width: 100%;
            > div {
                display: flex;
                justify-content: space-around;
            }
        }
        /deep/.ant-tabs-ink-bar {
            display: none !important;
        }
    }
    /deep/.image-left{
        width:130px!important;
        height:73px!important;
    }
    /deep/.image-right-title{
        margin-left:10px;
    }
    .across-bottom{
        margin-top:4px;
    }
    .list-box{
        &-item {
            .vertical-media-item {
                width: 100% !important;
                margin-bottom: 0;
            }

            width: calc(33% - 13px);

            .mobile-ml0 {
                margin-left: 0;
            }
        }
    }
}

@media screen and (max-width:768px) and (min-width:550px){
    .list-box{
        &-item{
            &:nth-of-type(3) {
                .vertical-media-item{
                    margin-top:0;
                }
            }
        }
    }
    .search-box,.content-p{
        margin-top: 10px;
    }
}

@media screen and (max-width:550px){
    .list-box{
        &-item{
            margin-left:20px;
            width: calc(50% - 10px);
            &:nth-of-type(2n-1) {
                margin-left:0;
            }
        }
    }
    .search-box,.content-p{
        margin-top: 10px;
    }
}

@media screen and (max-width:768px){
    .list-item{
        .desc{
            margin-top: 0;
            line-height: 1.2;
            .ml20{
                margin-left:10px;
            }
        }
        .video-btn{
            padding: 0 6px;
        }
    }
}
</style>