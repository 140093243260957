<template>
    <div class="component-box">
        <div class="meeting-list-box">
            <div class="meeting-item flex x-left y-center flex-wrap" v-for="(item,index) in dataList" :key="index">
                <div class="meeting-picture">
                    <img src="~@/assets/images/adv-banner.png" alt="">
                </div>
                <div class="meeting-info">
                    <div class="title row-2 strong">
                        2022南方消化疾病及消化内镜国际论坛
                    </div>
                    <div class="time mt20">
                        <i class="med med-31shijian"></i> 2022-09-11
                    </div>
                    <div class="address">
                        <i class="med med--didian"></i> 安徽省合肥市
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "meeting-item-row",
        props : {
            dataList : {
                type : Array,
                default : () => []
            }
        }
    }
</script>

<style scoped lang="less">
    .component-box{
        .meeting-list-box{
            .meeting-item{
                height: 105px;
                text-align: left;
                margin-bottom: 23px;
                cursor: pointer;
                .meeting-picture{
                    width: 187px;
                    height: 105px;
                    overflow: hidden;
                    img{
                        transition: all 1s;
                        display: block;
                        width: 100%;
                        height: 100%;
                    }
                    &:hover img{
                        transform: scale(1.2);
                    }
                }

                .meeting-info{
                    width: 183px;
                    height: 100%;
                    margin-left: 20px;
                    .title{
                        color: @title;
                        height: 44px;
                    }
                    .time , .address{
                        color: @desc;
                        font-size: 14px;
                    }
                }
            }
        }
    }
</style>