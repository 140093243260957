<template>
    <div class="wrapper flex flex-column">
        <Header @search="handlePageChangeEvent(1)" :navList="navList" :navCurrentId="navCurrentId" :logo="logoUrl"></Header>
        <div class="flex-item">
            <!--    条件筛选    -->
            <div class="filter-block container" v-if="subjectList.length">
                <filter-tab title="学科" :selected="subjectIndex" :list="subjectList" :isShowMore="true" @clickItem="handleFilterEvent('subjectIndex',$event)"></filter-tab>
            </div>

            <!-- 筛选条件 -->
            <div v-if="subjectIndex != -1 || $route.query.search_name" class="filter-condition mt20 active">
                <div class="container flex y-center x-left">
                    <div class="search-text">搜索条件：</div>
                    <a-breadcrumb class="search-breadcrumb">
                        <a-breadcrumb-item v-if="$route.query.search_name">{{ $route.query.search_name }}</a-breadcrumb-item>
                        <a-breadcrumb-item v-if="subjectIndex != -1">{{ subjectList[subjectIndex].operate_name }}
                        </a-breadcrumb-item>
                    </a-breadcrumb>
                    <a-button class="clear-btn" @click="handleClearEvent"  style="margin-left:20px;" size="small">清除</a-button>
                </div>
            </div>
            <div v-else class="filter-condition"></div>
            
            <!--    内容部分     -->
            <div class="content container flex x-left">
                <div class="left-box">
                    <a-spin :spinning="loading" tip="Loading...">
                        <course-item class="mh" target-type="1" width="230px" height="130px" :showClassNum="false" @clickEvent="handleNavigateEvent" :showDetail="false" :data-list="courseList"></course-item>
                        <a-empty v-if="!courseList.length && !loading" />
                        <div class="t-c mt30 special-pagination" v-if="listCount > 0">
                            <a-pagination :total="listCount" v-model:current="params.page" :page-size="params.limit" show-quick-jumper @change="handlePageChangeEvent"  :hideOnSinglePage="true"/>
                        </div>
                    </a-spin>
                </div>
                <div class="right-box ml35 flex-item">
                    <layout-right :config="layoutRightConfig"></layout-right>
                </div>
            </div>
        </div>
        <Footer></Footer>
    </div>
</template>

<script>
import Header from "@/components/layout/layout-header";
import layoutRight from '@/components/layout/layout-right';
import filterTab from "@/components/filter-tab.vue";
import courseItem from "@/components/courser-list-item";
import commonTitle from "@/components/common-title";
import meetingItemRow from "@/components/meeting-item-row";
import Footer from "@/components/layout/layout-footer";
import { videoLogo, mediaNavList } from "@/config/const";
import { jsonFormat } from '@/utils/jsonFormat';
import storage from 'store';
import { mapState } from 'vuex';

let layoutRightConfig = {
    news: {
        show: true, // 是否显示
        methods: 'post',
        title: '新闻资讯', // 标题
        data: {
            page: 1,
            limit: 5,
            type: 2,
        },   // 参数条件
    },
    correlationMeet: { //相关会议
        show: true, // 是否显示
        title: '推荐会议', // 标题
        methods: 'get',
        data: {
            year: new Date().getFullYear(),
            month: new Date().getMonth() + 1,
            page: 1,
            pageSize: 3,
            subject_ids: storage.get('subjectIds') || '',
        },   // 参数条件
    },
    correlationLive: { //相关直播
        show: true, // 是否显示
        methods:'get',
        title: '推荐直播', // 标题
        data: {
            tj_token: storage.get('tid'),
            page: 1,
            limit: 3,
            subject_ids: storage.get('subjectIds') || ''
        },   // 参数条件
    },
    // 指南
    guideDownload: {
        show: true, // 是否显示
        title: '临床指南下载', // 标题
        methods: 'post',
        data: {
            limit: 5,
            operate_id: "",
            page: 1,
        },   // 参数条件
    },
};

export default {
    name: "CourseList",
    components: {
        Header,
        layoutRight,
        filterTab,
        courseItem,
        commonTitle,
        meetingItemRow,
        Footer,
    },
    data() {
        return {
            navList: mediaNavList,
            logoUrl: videoLogo,
            navCurrentId: 3,
            // 专题视频列表请求参数
            params: {
                limit: 18,
                tj_token: storage.get('tid'),
                subject_ids: '',
                page: 1,
            },
            listCount: 0,
            subjectList: [],
            subjectIndex: -1,
            courseList: [],
            layoutRightConfig:{},
            loading: true,
        };
    },
    created() {
    },
    computed: {
        ...mapState(['userInfo'])
    },
    methods: {
        getSubject() {
            // url筛选学科
            let { subject_id } = this.$route.query;
            // 用户自己关注的学科
            subject_id = subject_id || this.userInfo.operate_subject;
            this.$store.dispatch('getSubjectList').then(res => {
                // 学科列表
                let subjectList = [];
                res.data.forEach((item,index) => {
                    item.name = item.operate_name;
                    if(subject_id == item.id){
                        this.subjectIndex = index;
                    }
                    subjectList.push(item);
                })
                this.subjectList = subjectList;

                this.handleFilterEvent();
            })
        },
         // 切换页码
        handlePageChangeEvent(page) {
            window.scrollTo(0,0);
            this.params.page = page
            this.getGuessLike()
        },

        getGuessLike() {
            this.loading = true;
            let data = { ...this.params };
            // 搜索筛选
            let { search_name } = this.$route.query;
            search_name && (data['search_name'] = search_name);
            this.request.get('getSpecialList', data).then(res => {
                let courseObj = {
                    img: 'default_pic_url',
                    real_name:'',
                }
                this.courseList = jsonFormat(res.data.list, courseObj)
                this.listCount = +res.data.count;
                this.loading = false
            })
        },
        // 跳转
        handleNavigateEvent(item) {
            this.$router.push({
                path: '/special-detail',
                query: {
                    type_id: item.id
                }
            })
        },

        // 清除事件
        handleClearEvent() {
            this.subjectIndex = -1
            if (this.$route.query.search_name) {
                this.$router.replace({
                    path: '/special-list'
                })
            }
            this.handleFilterEvent();
        },

        // 学科切换
        handleFilterEvent(key, e) {
            key && (this[key] = e)
            this.params.page = 1;

             // 用户url学科或者已选学科
            let subject_ids = this.$route.query.subject_id;
            let operate_subject = subject_ids;
            // 切换后的学科
            if(this.subjectIndex != -1){
                subject_ids = this.subjectList[this.subjectIndex].subject_ids;
                operate_subject = this.subjectList[this.subjectIndex].id;
                this.params.subject_ids = subject_ids
            }else {
                this.params.subject_ids && (delete this.params.subject_ids);
            }
            
            layoutRightConfig.news.data.operate_id = operate_subject;
            layoutRightConfig.guideDownload.data.operate_id = operate_subject;
            layoutRightConfig.correlationMeet.data.subject_ids = subject_ids;
            layoutRightConfig.correlationLive.data.subject_ids = subject_ids;

            this.layoutRightConfig = JSON.parse(JSON.stringify(layoutRightConfig));

            this.getGuessLike();
        },
        pageInit() {
            
        },
    },
    created(){
        this.getSubject();
    }
};
</script>

<style scoped lang="less" scoped>
.wrapper {
    min-height: 100vh;
    background: url("~@/assets/images/live-nav-top-bg.png") repeat-x;
    background-size: 1920 auto;
}
.filter-block {
    background: #ffffff;
    border-radius: 10px;
    padding: 5px 15px;
}

.content {
    background: #ffffff;
    border-radius: 10px;
    padding: 20px;
    margin: 17px auto 0;
    .left-box {
        width: 730px;
        .mh {
            min-height: 500px;
        }
        .sort-block {
            height: 40px;
            background: #f9f9f9;
            border-radius: 5px;
            .sort-item {
                color: @title;
                margin-right: 60px;
                cursor: pointer;
                &.active {
                    color: @theme;
                    font-weight: bold;
                }
                img {
                    width: 13px;
                }
            }
            .sort-item:nth-child(1) {
                margin-left: 40px;
            }
        }
    }

    .right-box {
        .top-list {
            .ranking-item {
                color: @title;
                margin-bottom: 15px;
                font-weight: bold;
                cursor: pointer;
                img {
                    width: 15px;
                }
                &:nth-child(n + 4) {
                    color: @desc;
                    font-weight: 400;
                }
            }
        }
    }
}
@media screen and (max-width:768px){
    .wrapper{
         .filter-condition.active{
            margin-top:10px;
            .container{
                padding:0 10px;
                display: flex;
            }
        }
        .filter-block{
            padding: 2px 0;
            border-radius: 0;
            position: sticky;
            top: 50px;
            background: #f2f4fa;
            z-index: 9;
        }
        .content{
            display: block;
            padding: 10px;
            .left-box{
                width: 100%;
                .mh{
                    min-height: 0;
                }
                .special-pagination{
                    margin-top: 0;
                }
            }
            .right-box{
                width: 100%;
                margin-left: 0;
                margin-top: 50px;
            }
        }
    }
}
</style>